import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Container from "@components/container"
import AnchorIH from "@components/anchorIH"

import { Heading1 } from "@styles/ui"

// Assets
import logo from "@images/logos/logo-colourful-outline.svg"

const Sitemap = ({ location }) => {
  const narcolepsyLinks = [
    {
      link: "/narcolepsy/",
      text: "XYWAV for Narcolepsy",
    },
    {
      link: "/narcolepsy/comorbidities/",
      text: "Narcolepsy & Potential Comorbidities",
    },
    {
      link: "/narcolepsy/sodium-content/",
      text: "Sodium Intake & XYWAV",
    },
    {
      link: "/narcolepsy/efficacy-and-safety/",
      text: "Efficacy for Cataplexy & EDS",
    },
    {
      link: "/narcolepsy/safety-and-tolerability/",
      text: "Safety & Tolerability",
    },
    {
      link: "/narcolepsy/transitioning-from-xyrem/",
      text: "Transitioning from XYREM to XYWAV",
    },
    {
      link: "/narcolepsy/dosing-and-titration/",
      text: "Dosing & Titration for Patients New to Oxybate",
    },
    {
      link: "/narcolepsy/prescriber-resources/",
      text: "Support & Resources",
    },
  ]

  const IHLinks = [
    {
      link: "/idiopathic-hypersomnia/",
      text: "XYWAV for Idiopathic Hypersomnia",
    },
    {
      link: "/idiopathic-hypersomnia/diagnosis/",
      text: "Identifying Idiopathic Hypersomnia",
    },
    {
      text: "Why Consider XYWAV",
      link: "/idiopathic-hypersomnia/consider-xywav-treatment/",
    },
    {
      link: "/idiopathic-hypersomnia/efficacy/",
      text: "Efficacy in Idiopathic Hypersomnia",
    },
    {
      link: "/idiopathic-hypersomnia/safety-and-tolerability/",
      text: "Safety & Tolerability",
    },
    {
      link: "/idiopathic-hypersomnia/dosing/",
      text: "Dosing & Titration",
    },
    {
      link: "/idiopathic-hypersomnia/resources/",
      text: "Support & Resources",
    },
  ]

  const otherLinks = [
    {
      link: "/cost-coverage/",
      text: "Cost & Coverage",
    },
    {
      link: "/xywav-xyrem-program/",
      text: "XYWAV & XYREM REMS",
    },
    {
      link: "/jazzcares-for-xywav/",
      text: (
        <>
          JazzCares
          <sup tw=" inline-block pb-[11px]">&reg;</sup> for XYWAV
        </>
      ),
    },
    {
      link: "/sign-up/",
      text: "Sign Up for Info",
    },
  ]
  return (
    <Layout
      location={location}
      copyright={
        <>
          © 2024 Jazz Pharmaceuticals plc or its subsidiaries{" "}
          <br tw="block md:hidden" />
          US-XYW-2200207 Rev0824
        </>
      }
    >
      <Seo
        title="XYWAV Sitemap | XYWAV HCP"
        description="Explore the XYWAV® (calcium, magnesium, potassium, and sodium oxybates) sitemap for healthcare providers. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <section tw="relative cost-and-coverage-hero-gradient rounded-bl-[105px] pt-[68px] pb-[114px] overflow-hidden lg:(py-[76px] rounded-bl-[150px])">
        <img
          src={logo}
          tw="absolute right-[-115px] bottom-[-80px] w-[372px] h-[246px] lg:(w-[767px] h-[505px] bottom-[-180px] right-[-120px])"
          alt="XYWAV swirl"
        />
        <div tw="relative mb-10 rounded-br-[100px]  lg:(pb-10 mb-0 rounded-none overflow-visible)">
          <Container>
            <h1 css={[Heading1, tw`font-semibold`]}>Site Map</h1>
          </Container>
        </div>
        <Container>
          <div css={[tw`mb-5 lg:(mb-6)`]}>
            <AnchorIH link="/" underline textBlack inline>
              Dual-Indication Homepage
            </AnchorIH>
          </div>

          <h2 tw="font-bold">Narcolepsy</h2>
          <ul tw="ml-[30px] mb-5 mt-2 padding-inline-end[30px] md:padding-inline-end[0px] lg:(ml-[32px] mb-6)">
            {narcolepsyLinks.map((link, i) => {
              return (
                <li key={"narc-" + i} css={[tw`mb-5 md:mb-2`]}>
                  <AnchorIH link={link.link} underline textBlack inline>
                    {link.text}
                  </AnchorIH>
                </li>
              )
            })}
          </ul>

          <h2 tw="font-bold">Idiopathic Hypersomnia</h2>
          <ul tw="ml-[30px] mb-5 mt-2 padding-inline-end[30px] md:padding-inline-end[0px] lg:(ml-[32px] mb-6)">
            {IHLinks.map((link, i) => {
              return (
                <li key={"ih-" + i} css={[tw`mb-5 md:mb-2`]}>
                  <AnchorIH link={link.link} underline textBlack inline>
                    {link.text}
                  </AnchorIH>
                </li>
              )
            })}
          </ul>

          <ul>
            {otherLinks.map((link, i) => {
              return (
                <li key={"other-" + i} css={[tw`mb-5 md:(w-1/3) lg:(mb-6)`]}>
                  <AnchorIH link={link.link} underline textBlack>
                    {link.text}
                  </AnchorIH>
                </li>
              )
            })}
          </ul>
        </Container>
      </section>
    </Layout>
  )
}

export default Sitemap

Sitemap.propTypes = {
  location: PropTypes.object.isRequired,
}
